import { escape, op } from "arquero";
import { ChevronRight } from "lucide-react";
import { useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import useStore from "../../lib/store";
import {
  fillMissingData,
  getMaxValues,
  getPieData,
  MonthData,
  nonRecycleTypes,
  recyclePalette,
  wastePalette,
} from "../../lib/utils";
import PieChart from "../PieChat";
import { Button } from "../ui/button";
import { Switch } from "../ui/switch";
interface VizCardProps {
  vizTitle: string;
  onClick: () => void;
  children?: React.ReactNode;
  title: string;
  value: number;
  unit: string;
  SideIcon: React.ReactNode;
}

const VizCard = ({
  vizTitle,
  onClick,
  children,
  title,
  value = 0,
  unit,
  SideIcon,
}: VizCardProps) => {
  return (
    <div
      className="h-full flex flex-col rounded-2xl border border-current bg-white p-[20px]"
      style={{ boxShadow: "0 2px 8px 0 rgba(231, 26, 26, 0.4)" }}
    >
      <div className="flex h-[88px] border-current border-b-4 items-center pb-4">
        <div>{SideIcon}</div>
        <div className="h-full flex flex-col justify-center items-center grow">
          <div className="text-base">{title}</div>
          <div className="text-3xl font-medium flex gap-2 items-end">
            {value.toLocaleString("jp-ja", { maximumFractionDigits: 1 })}{" "}
            <p className="text-xl">{unit}</p>
          </div>
        </div>
      </div>
      <div className="flex h-[140px] items-center">
        <h3 className="self-center font-medium text-xs w-[122px]">
          {vizTitle}
        </h3>
        {children}
      </div>
      <Button
        className="m-auto mb-0 text-current border w-[121px] h-[28px] py-1 pl-4 pr-2 text-xs border-current"
        variant="outline"
        onClick={onClick}
      >
        {"詳しく見る"} <ChevronRight className="ml-3 w-[20px] h-[20px]" />
      </Button>
    </div>
  );
};

export default function SummaryDashboard() {
  const [setDashboardIndex, unit, table, filteredTable, startMonth] = useStore(
    (state) => [
      state.setDashboardIndex,
      state.unit,
      state.table,
      state.filteredTable,
      state.startMonth,
    ]
  );

  const [comparison, setComparison] = useState(false);
  const currentYear = startMonth.year();

  const recyleAmount =
    filteredTable
      .filter(escape((d: any) => !nonRecycleTypes.includes(d["recycle_type"])))
      .rollup({ sum: op.sum(`waste_amount_${unit}`) })
      .get("sum") || 0;

  const totalAmount =
    filteredTable.rollup({ sum: op.sum(`waste_amount_${unit}`) }).get("sum") ||
    0;

  const _barData = table
    .derive({
      month: (d: any) => op.month(d.date),
      year: (d: any) => op.year(d.date),
    })
    .rename({ [`waste_amount_${unit}`]: "waste_amount" })
    .select("report_type_name", "month", "year", "waste_amount")
    .groupby("month")
    .pivot("year", { value: op.sum(`waste_amount`) })
    .objects();

  const maxValues = getMaxValues(_barData as MonthData[]);

  const barData = fillMissingData(_barData as MonthData[]);

  const CustomizedLabel = ({
    x,
    fill,
    isUpper,
    value,
  }: {
    x: number;
    fill: string;
    isUpper?: boolean;
    value: number;
  }) => {
    return (
      <text
        x={!comparison ? x : isUpper ? x : x}
        dx={comparison ? 10 : 20}
        y={isUpper ? 10 : 10}
        fill={fill}
        fontSize={8}
        textAnchor="middle"
      >
        {value > 1000 ? `${Math.floor(value / 100) / 10}t` : Math.floor(value)}
      </text>
    );
  };

  const ColoredCustomizedLabel =
    (fill: string, isUpper: boolean) =>
    ({ x, y, value }: { x: number; y: number; value: number }) =>
      CustomizedLabel({ fill, x, isUpper, value });

  return (
    <div className="flex flex-col gap-4 h-full items-center">
      <div className="grid grid-cols-3 h-[328px] w-[976px] justify-between gap-6">
        <div className="flex flex-col">
          <VizCard
            vizTitle="廃棄物品目"
            onClick={() => {
              setDashboardIndex(1);
            }}
            title={`排出量`}
            value={filteredTable
              .rollup({ waste_amount_total: op.sum(`waste_amount_${unit}`) })
              .get("waste_amount_total", 0)}
            unit={unit}
            SideIcon={
              <img
                src={require("img/icon_emission.png")}
                alt="廃棄物品目"
                className="w-[80px] h-[80px]"
              />
            }
          >
            <PieChart
              data={getPieData(
                filteredTable,
                "report_type_name",
                `waste_amount_${unit}`
              )}
              palette={wastePalette}
            />
          </VizCard>
        </div>
        <div className="flex flex-col gap-4">
          <VizCard
            vizTitle="リサイクル方法"
            onClick={() => {
              setDashboardIndex(2);
            }}
            title="リサイクル率"
            value={(recyleAmount / totalAmount) * 100}
            unit="%"
            SideIcon={
              <img
                src={require("img/icon_recycle.png")}
                alt="リサイクル方法"
                className="w-[80px] h-[80px]"
              />
            }
          >
            <PieChart
              data={getPieData(
                filteredTable,
                "recycle_type",
                `waste_amount_${unit}`
              )}
              palette={recyclePalette}
            />
          </VizCard>
        </div>
        <div className="flex flex-col gap-4">
          <VizCard
            vizTitle="CO2排出量　処分方法別"
            onClick={() => {
              setDashboardIndex(3);
            }}
            title={`CO2排出量`}
            value={Math.floor(
              filteredTable
                .rollup({ co2_amount: op.sum(`co2_amount`) })
                .get("co2_amount", 0)!!
            )}
            unit={unit}
            SideIcon={
              <img
                src={require("img/icon_co2.png")}
                alt="CO2排出量"
                className="w-[80px] h-[80px]"
              />
            }
          >
            <PieChart
              data={getPieData(filteredTable, "recycle_type", "co2_amount")}
              palette={recyclePalette}
            />
          </VizCard>
        </div>
      </div>
      <div className="flex justify-between h-[250px] w-[976px]">
        <div className="flex flex-col p-4 px-4 w-full bg-white">
          <div className="flex justify-between">
            <div className="font-bold text-sm">
              月別 廃棄物排出量{" "}
              <a className="text-[#727272] text-[10px] font-normal">
                （表示単位：t）
              </a>
            </div>
            <div className="flex gap-2.5 items-center">
              <div className="text-[#727272] text-xs">昨年度と比較する</div>
              <div>
                <Switch checked={comparison} onCheckedChange={setComparison} />
              </div>
            </div>
          </div>
          <div className="w-full h-[200px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={barData}
                margin={{
                  top: 20,
                  bottom: 5,
                  right: 10,
                }}
                barGap={comparison ? 3 : 30}
              >
                <YAxis
                  hide={false}
                  type="number"
                  interval={0}
                  // domain={[0, 35000]}
                  // ticks={[0, 7000, 14000, 21000, 28000, 35000]}
                  tickFormatter={(tick) =>
                    tick.toLocaleString("jp-ja", { maximumFractionDigits: 1 })
                  }
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: "10px" }}
                />
                <XAxis
                  dataKey="month"
                  scale="point"
                  padding={{ left: 30, right: 10 }}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(tick) => `${tick + 1}月`}
                  tick={{ fontSize: "12px" }}
                />
                <Legend
                  wrapperStyle={{
                    fontSize: "10px",
                  }}
                />
                {[0, 1, 2, 3, 4]
                  .map((i) =>
                    Math.round(
                      (i *
                        (comparison
                          ? maxValues[currentYear]
                          : Math.max(
                              maxValues[currentYear],
                              maxValues[currentYear - 1]
                            )) *
                        1.2) /
                        5
                    )
                  )
                  .map((value) => (
                    <ReferenceLine key={value} y={value} stroke="#e5e7eb" />
                  ))}

                {comparison && (
                  <Bar
                    dataKey={currentYear - 1}
                    fill="#FB858E"
                    // background={{ fill: "#EBEBEB" }} from figma FB858E
                    label={ColoredCustomizedLabel("#FB858E", true)}
                  />
                )}

                <Bar
                  dataKey={currentYear}
                  fill="#E60013"
                  // background={{ fill: "#EBEBEB" }} from figma F34755
                  label={ColoredCustomizedLabel("#E60013", false)}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="">
        <img className="z-[-1]" src={require("img/company_panel_park.png")} />
      </div>
    </div>
  );
}
