import { escape, from } from "arquero";
import ColumnTable from "arquero/dist/types/table/column-table";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { create } from "zustand";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface AppState {
  companyName: string;
  companySite: string;
  unit: string;
  recycleType: string;
  startMonth: dayjs.Dayjs;
  endMonth: dayjs.Dayjs;
  recyleSummary: any[];
  dashboardIndex: number;
  table: ColumnTable;
  filteredTable: ColumnTable;
  currentYearTable: ColumnTable;
  userName: String;
  setCurrentYearTable: () => void;
  setFilteredTable: () => void;
  setCompanySite: (value: string) => void;
  setRecycleType: (value: string) => void;
  setDashboardIndex: (value: number) => void;
  setCompanyName: (value: string) => void;
  setStartMonth: (value: dayjs.Dayjs) => void;
  setEndMonth: (value: dayjs.Dayjs) => void;
  setUnit: (value: string) => void;
  setTable: (records: any[]) => void;
  setUserName: (value: string) => void;
}

const useStore = create<AppState>((set, get) => ({
  table: from([]),
  companyName: "",
  companySite: "全て",
  unit: "kg",
  recycleType: "全て",
  startMonth: dayjs("2023-01-01"),
  endMonth: dayjs("2023-12-31"),
  recyleSummary: [],
  dashboardIndex: 0,
  filteredTable: from([]),
  currentYearTable: from([]),
  userName: "",
  setCurrentYearTable: () => {
    set({
      currentYearTable: get().table.filter(
        escape(
          (d: any) =>
            dayjs(d.date).isSameOrAfter(get().startMonth.startOf("year")) &&
            dayjs(d.date).isSameOrBefore(get().startMonth.endOf("year"))
        )
      ),
    });
  },
  setFilteredTable: () => {
    set({
      filteredTable: get().table.filter(
        escape(
          (d: any) =>
            dayjs(d.date).isSameOrAfter(get().startMonth.startOf("month")) &&
            dayjs(d.date).isSameOrBefore(get().endMonth.endOf("month")) &&
            (get().recycleType === "全て" ||
              d.recycle_type === get().recycleType) &&
            (get().companySite === "全て" ||
              d.company_site_name === get().companySite)
        )
      ),
    });
  },
  setCompanySite: (value: string) => {
    set({
      companySite: value,
    });
    get().setFilteredTable();
  },
  setRecycleType: (value: string) => {
    set({
      recycleType: value,
    });
    get().setFilteredTable();
  },
  setTable: (records: any[]) => {
    set({
      table: from(records),
      startMonth: dayjs(records[records.length - 1].date).startOf("year"),
      endMonth: dayjs(records[records.length - 1].date),
    });
    get().setFilteredTable();
    get().setCurrentYearTable();
  },
  setDashboardIndex: (value: number) => set({ dashboardIndex: value }),
  setCompanyName: (value: string) => set({ companyName: value }),
  setStartMonth: (value: dayjs.Dayjs) => {
    set({
      startMonth: value,
      // filteredTable: get().table.filter(
      //   escape((d: any) => dayjs(d.date).isAfter(value))
      // ),
    });
    get().setFilteredTable();
    get().setCurrentYearTable();
  },
  setEndMonth: (value: dayjs.Dayjs) => {
    set({
      endMonth: value,
      // filteredTable: get().table.filter(
      //   escape((d: any) => dayjs(d.date).isBefore(value))
      // ),
    });
    get().setFilteredTable();
  },
  setUnit: (value: string) => set({ unit: value }),
  setUserName: (value: string) => set({ userName: value }),
}));

export default useStore;
