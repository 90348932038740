export const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any[];
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border p-2">
        <p className="">{`${
          payload[0].name
        } : ${payload[0].value.toLocaleString("jp-ja", {
          maximumFractionDigits: 1,
        })}`}</p>
      </div>
    );
  }

  return null;
};
