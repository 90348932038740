import {
  Legend,
  Pie,
  PieChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  DefaultLegendContent,
  DefaultLegendContentProps,
} from "recharts";
import { CustomTooltip } from "./CustomTooltip";

export default function PieComponent({
  data,
  palette,
}: {
  data: any[];
  palette: { [key: string]: string[] };
}) {
  const CustomLegend = (props: DefaultLegendContentProps) => {
    const { payload } = props;
    return <DefaultLegendContent payload={payload?.slice(0, 3)} />;
  };

  return (
    <ResponsiveContainer>
      <PieChart className="pt-2">
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          innerRadius={35}
          outerRadius={50}
          fill="#82ca9d"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                (palette[entry["name"]] || ["#8884d8", "#82ca9d", "#abaa88"])[0]
              }
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          wrapperStyle={{
            fontSize: ".7rem",
            width: "269px",
            left: "-80px",
            bottom: "-20px",
          }}
          content={<CustomLegend />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
