import { op } from "arquero";
import useStore from "../../lib/store";
import { BarChart, Bar, ResponsiveContainer, XAxis, Tooltip } from "recharts";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../DataTable";
import {
  floor,
  recycleTypeOrder,
  recyclePalette,
  wastePalette,
  wasteOrder,
} from "../../lib/utils";
import { CustomTooltip } from "../CustomTooltip";

export default function Co2Dashboard() {
  const [filteredTable] = useStore((state) => [state.filteredTable]);

  const recycleBarData = filteredTable
    .derive({ month: (d: any) => op.month(d.date) })
    .select("recycle_type", "month", "co2_amount")
    .groupby("month")
    .pivot("recycle_type", { value: (d: any) => op.sum(d.co2_amount) });

  const recycleTableData = filteredTable
    .derive({ month: (d: any) => op.month(d.date) })
    .select("recycle_type", "month", "co2_amount")
    .groupby("recycle_type")
    .pivot("month", { value: op.sum("co2_amount") })
    .objects();

  recycleTableData.sort(
    (a: any, b: any) =>
      recycleTypeOrder.indexOf(a["recycle_type"]) -
      recycleTypeOrder.indexOf(b["recycle_type"])
  );

  const wasteBarData = filteredTable
    .derive({ month: (d: any) => op.month(d.date) })
    .select("report_type_name", "month", "co2_amount")
    .groupby("month")
    .pivot("report_type_name", { value: (d: any) => op.sum(d.co2_amount) });

  const wasteTableData = filteredTable
    .derive({ month: (d: any) => op.month(d.date) })
    .select("report_type_name", "month", "co2_amount")
    .groupby("report_type_name")
    .pivot("month", { value: op.sum("co2_amount") })
    .objects();

  wasteTableData.sort(
    (a: any, b: any) =>
      wasteOrder.indexOf(a["report_type_name"]) -
      wasteOrder.indexOf(b["report_type_name"])
  );

  const columns: ColumnDef<any>[] = [
    ...Array.from({ length: 12 }, (_, i) => ({
      accessorKey: `${i}`,
      header: `${i + 1}月`,
      cell: ({ row }: { row: any }) => {
        const value = row.getValue(`${i}`);
        if (value === undefined) {
          return 0;
        }
        return floor(value).toLocaleString("jp-ja", {
          maximumFractionDigits: 1,
        });
      },
    })),
  ];
  const recyleColumns = [
    {
      accessorKey: "recycle_type",
      header: "",
      enableResizing: true,
      cell: ({ row }: { row: any }) => {
        const value = row.getValue("recycle_type");
        return (
          <p className="flex gap-2 items-center">
            <span
              className="h-3 w-3 inline-block"
              style={{
                backgroundColor: (recyclePalette[value] || [""])[0],
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            {value}
          </p>
        );
      },
    },
    ...columns,
  ];

  const wasteColumns = [
    {
      accessorKey: "report_type_name",
      header: "",
      enableResizing: true,
      cell: ({ row }: { row: any }) => {
        const value = row.getValue("report_type_name");
        return (
          <p className="flex gap-2 items-center">
            <span
              className="h-3 w-3 inline-block"
              style={{
                backgroundColor: (wastePalette[value] || [""])[0],
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            {value}
          </p>
        );
      },
    },
    ...columns,
  ];

  return (
    <div className="hidden-scrollbar flex flex-col p-5 h-full overflow-scroll gap-5 bg-[url('img/co2_background.png')] subpage-bg-half-size bg-no-repeat bg-right-top">
      <div className="flex flex-col rounded-2xl bg-white p-5 gap-3">
        <div className="font-bold">処理方法別 CO2排出量（月次）</div>
        <div className="flex h-64 items-center ">
          <img
            src={require("img/co2_panel_co2.png")}
            alt="CO2ta"
            className="h-auto w-28"
          />
          <ResponsiveContainer>
            <BarChart data={recycleBarData.objects()}>
              <XAxis
                dataKey="month"
                tickFormatter={(month) => `${month + 1}月`}
              />
              {Object.keys(recycleBarData.columns())
                .sort(
                  (a: string, b: string) =>
                    recycleTypeOrder.indexOf(b) - recycleTypeOrder.indexOf(a)
                )
                .filter((column: string) => column !== "month")
                .map((column: string, index: number) => (
                  <Bar
                    dataKey={column}
                    key={column}
                    stackId="a"
                    fill={(recyclePalette[column] || [""])[0]}
                    background={index === 0 ? { fill: "#EBEBEB" } : undefined}
                  />
                ))}
              <Tooltip cursor={false} content={CustomTooltip} shared={false} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div>
          <DataTable data={recycleTableData} columns={recyleColumns} />
        </div>
      </div>
      <div className="flex flex-col rounded-2xl bg-white p-5 gap-3">
        <div className="font-bold">品目別 CO2排出量（月次）</div>
        <div className="flex h-64 w-full items-center">
          <img
            src={require("img/co2_panel_factory1.png")}
            alt="CO2ta"
            className="h-auto w-36"
          />
          <ResponsiveContainer>
            <BarChart data={wasteBarData.objects()}>
              <XAxis
                dataKey="month"
                tickFormatter={(month) => `${month + 1}月`}
              />
              {Object.keys(wasteBarData.columns())
                .sort(
                  (a: string, b: string) =>
                    wasteOrder.indexOf(b) - wasteOrder.indexOf(a)
                )
                .filter((column: string) => column !== "month")
                .map((column: string, index: number) => (
                  <Bar
                    dataKey={column}
                    key={column}
                    stackId="a"
                    fill={(wastePalette[column] || [""])[0]}
                    background={index === 0 ? { fill: "#EBEBEB" } : undefined}
                  />
                ))}
              <Tooltip cursor={false} content={CustomTooltip} shared={false} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div>
          <div>
            <DataTable data={wasteTableData} columns={wasteColumns} />
          </div>
        </div>
      </div>
      <div className="text-xs">
        ※環境省の
        <a
          href="https://www.env.go.jp/earth/ondanka/supply_chain/gvc/files/tools/DB_V2-5.pdf"
          target="_blank"
          className="text-blue-600"
          rel="noreferrer"
        >
          「排出原単位データベース」
        </a>
        の資料に基づく　※リサイクル内訳はマテリアル、ケミカル、サーマル、飼料化、メタン発酵、堆肥です。
      </div>
    </div>
  );
}
