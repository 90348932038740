import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

import { translations } from "@aws-amplify/ui";
import { Authenticator, Heading, useTheme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "aws-amplify";

import Home from "./Home";

I18n.setLanguage("ja");
I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage("ja", {
  "Enter your Username": "ユーザー名を入力",
});
Amplify.configure(awsExports);

const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          サインイン
        </Heading>
      );
    },
    Footer() {
      return <div></div>;
    },
  },
};

function App() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-between w-[1280px] m-auto">
      <Authenticator components={components} hideSignUp={true}>
        {({ signOut, user }) => <Home signOut={signOut} />}
      </Authenticator>
    </main>
  );
}

export default App;
