/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getRecord = /* GraphQL */ `query GetRecord($id: ID!) {
  getRecord(id: $id) {
    id
    date
    company_cd
    company_name
    company_site_cd
    company_site_name
    report_type_name
    waste_amount
    waste_amount_unit
    waste_amount_kg
    waste_amount_kg_unit
    waste_amount_m3
    waste_amount_m3_unit
    transport_company_name
    disposal_company_name
    disposal_site_name
    recycle_type
    co2_amount
    recycle_target
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRecordQueryVariables, APITypes.GetRecordQuery>;
export const listRecords = /* GraphQL */ `query ListRecords(
  $filter: ModelRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      company_cd
      company_name
      company_site_cd
      company_site_name
      report_type_name
      waste_amount
      waste_amount_unit
      waste_amount_kg
      waste_amount_kg_unit
      waste_amount_m3
      waste_amount_m3_unit
      transport_company_name
      disposal_company_name
      disposal_site_name
      recycle_type
      co2_amount
      recycle_target
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRecordsQueryVariables,
  APITypes.ListRecordsQuery
>;
export const recordsByCompany_nameAndDate = /* GraphQL */ `query RecordsByCompany_nameAndDate(
  $company_name: String!
  $date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  recordsByCompany_nameAndDate(
    company_name: $company_name
    date: $date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      date
      company_cd
      company_name
      company_site_cd
      company_site_name
      report_type_name
      waste_amount
      waste_amount_unit
      waste_amount_kg
      waste_amount_kg_unit
      waste_amount_m3
      waste_amount_m3_unit
      transport_company_name
      disposal_company_name
      disposal_site_name
      recycle_type
      co2_amount
      recycle_target
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RecordsByCompany_nameAndDateQueryVariables,
  APITypes.RecordsByCompany_nameAndDateQuery
>;
