import { op } from "arquero";
import ColumnTable from "arquero/dist/types/table/column-table";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getPieData(
  table: ColumnTable,
  groupby: string,
  sumKey: string
) {
  return table
    .groupby(groupby)
    .rollup({ count: op.sum(sumKey) })
    .rename({ [groupby]: "name", count: "value" })
    .objects();
}

export function floor(num?: number) {
  if (num === undefined) return 0;
  if (num > 1) {
    return Math.floor(num);
  } else {
    return num.toLocaleString("jp-ja", { maximumFractionDigits: 1 });
  }
}

export const recycleTypeOrder = [
  "マテリアル",
  "ケミカル",
  "サーマル",
  "飼料化",
  "メタン発酵",
  "メタン化",
  "堆肥化",
  "埋立",
  "焼却後埋立",
];

export const wasteOrder = [
  "燃え殻",
  "汚泥",
  "廃油",
  "廃酸",
  "廃アルカリ",
  "廃プラスチック",
  "ゴムくず",
  "金属くず",
  "ガラス・陶磁器くず",
  "鉱さい",
  "がれき類",
  "ばいじん",
  "紙くず",
  "木くず",
  "繊維くず",
  "動物系固形不要物",
  "動植物性残さ",
  "動・植物性残さ",
  "燃動物のふん尿",
  "動物の死体",
  "その他",
];

export const nonRecycleTypes = ["埋立", "焼却後埋立"];

export const recyclePalette: { [key: string]: string[] } = {
  マテリアル: ["#D2B014", "#E5C015", "#EDCE40", "#F2DA6F", "#F6E69D"],
  ケミカル: ["#C2CF3A", "#CEDD3C", "#D5E43A", "#E2ED6D", "#EFF5B2"],
  サーマル: ["#94C144", "#98CE36", "#9BDA2B", "#B0E157", "#C6E986"],

  飼料化: ["#94C144", "#98CE36", "#9BDA2B", "#B0E157", "#C6E986"],
  メタン発酵: ["#1A896E", "#1FA484", "#24BC98", "#27CEA6", "#15E0AF"],
  メタン化: ["#1A896E", "#1FA484", "#24BC98", "#27CEA6", "#15E0AF"],
  堆肥化: ["#079289", "#64C4A1", "#89D2B8", "#AEE0CE", "#D3EEE4"],

  焼却後埋立: ["#80695D", "#9A8174", "#B29F95", "#C4BDB6", "#E6DBD7"],
  埋立: ["#8F8676", "#A69E92", "#BCB7AE", "#D2D0CA", "#E8E9E6"],
};

export const wastePalette: { [key: string]: string[] } = {
  燃え殻: ["#D85C50", "#E47D6C", "#EF9E88", "#FABFA4", "#FFDFC0"],
  汚泥: ["#76948C", "#88A8A1", "#9ABCB6", "#ACCFCB", "#BEE3E0"],
  廃油: ["#A17C48", "#B59565", "#C9AE82", "#DDC79F", "#F1E0BC"],
  廃酸: ["#7B6C8D", "#8C80A0", "#9D94B3", "#AEA8C6", "#BFBCD9"],
  廃アルカリ: ["#6C847D", "#7C9590", "#8CA6A3", "#9CB7B6", "#ACC8C9"],
  廃プラスチック: ["#EB5223", "#EF7752", "#F49C81", "#F8C0B0", "#FCE5DE"],
  ゴムくず: ["#D6B056", "#DEBF6E", "#E6CE86", "#EEDD9E", "#F6ECB6"],
  金属くず: ["#3447EE", "#6573F2", "#939DF6", "#C2C7FA", "#F1F2FE"],
  "ガラス・陶磁器くず": ["#9152A3", "#A870B8", "#BD93C9", "#D1B4DA", "#E6D6EB"],
  鉱さい: ["#9F2FD5", "#BC5EE9", "#CF8CEF", "#D8A1F2", "#E1B7F5"],
  がれき類: ["#3447EE", "#6573F2", "#939DF6", "#C2C7FA", "#F1F2FE"],
  ばいじん: ["#672EE0", "#885BE7", "#A786ED", "#C8B4F4", "#E2D7F9"],
  紙くず: ["#3D458F", "#4C56B3", "#7078C2", "#959BD2", "#959BD2"],
  木くず: ["#AA6E4E", "#BC8565", "#CE9C7C", "#E0B393", "#F2CAAA"],
  繊維くず: ["#24567A", "#2F72A2", "#3E8DC6", "#66A5D2", "#8DBCDD"],
  "動・植物性残さ": ["#8B6B57", "#9C8069", "#AD957B", "#BEAA8D", "#CFBF9F"],
  引火性廃油: ["#3E7B69", "#4E8B7E", "#5E9B93", "#6EABA8", "#7EBBBD"],
  乾電池: ["#895E63", "#9A7178", "#AB848D", "#BC97A2", "#CDAAB7"],
  感染性廃棄物: ["#C27B7E", "#CE9298", "#DBA9B2", "#E7BFCC", "#F4D6E6"],
  強酸: ["#8B6B57", "#9C8069", "#AD957B", "#BEAA8D", "#CFBF9F"],
  その他: ["#567382", "#688492", "#7A95A2", "#8BA6B2", "#9DB7C2"],
};
export interface MonthData {
  [year: string]: number | undefined;
  month: number;
}

export function fillMissingData(data: MonthData[]): MonthData[] {
  if (data.length === 0) return [];

  // Extract the year keys from the first item
  const yearKeys = Object.keys(data[0]).filter((key) => key !== "month");
  const filledData: MonthData[] = [];

  for (let month = 0; month < 12; month++) {
    const existingData = data.find((item) => item.month === month);

    if (existingData) {
      const filledItem: MonthData = { month };
      yearKeys.forEach((year) => {
        filledItem[year] = existingData[year] ?? 0;
      });
      filledData.push(filledItem);
    } else {
      const filledItem: MonthData = { month };
      yearKeys.forEach((year) => {
        filledItem[year] = 0;
      });
      filledData.push(filledItem);
    }
  }

  return filledData;
}

export function getMaxValues(data: MonthData[]): {
  [year: string]: number;
} {
  if (data.length === 0) return {};

  const yearKeys = Object.keys(data[0]).filter((key) => key !== "month");
  const maxValues: { [year: string]: number } = {};

  yearKeys.forEach((year) => {
    const values = data
      .map((item) => item[year])
      .filter((value): value is number => value !== undefined);
    maxValues[year] = values.length > 0 ? Math.max(...values) : 0;
  });

  return maxValues;
}
