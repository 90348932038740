import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  ColumnDef,
  SortingState,
  getSortedRowModel,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";
import { useState } from "react";
import { cn, floor } from "../lib/utils";
import { ChevronsUpDown } from "lucide-react";
import useStore from "../lib/store";
import { escape, op } from "arquero";
import { CustomTooltip } from "./CustomTooltip";

interface DetailCardProps {
  name: string;
  filterKey: string;
  colors: string[];
}

interface DataTableProps {
  columns: any[];
  data: {}[];
}

export function DataTable({ columns, data }: DataTableProps) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div className="border-0">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className={cn(
                      "text-[10px] h-4 border-0",
                      header.id === "index" ? "w-4" : ""
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="text-xs h-7"
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className={cn(
                      " border-gray-200 p-2",
                      cell.id.endsWith("id") ? "text-start" : "text-end"
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

export default function DetailCard({
  name,
  colors,
  filterKey,
}: DetailCardProps) {
  const [filteredTable, unit] = useStore((state) => [
    state.filteredTable,
    state.unit,
  ]);

  const barData = [
    filteredTable
      .filter(escape((d: any) => d[filterKey] === name))
      .groupby("disposal_site_name")
      .rollup({ sum: op.sum(`waste_amount_${unit}`) })
      .objects()
      .reduce((acc, cur: any) => {
        return {
          ...acc,
          [cur.disposal_site_name]: cur.sum,
        };
      }, {}),
  ];

  const data = filteredTable
    .filter(escape((d: any) => d[filterKey] === name))
    .groupby("disposal_site_name")
    .rollup({ sum: op.sum(`waste_amount_${unit}`) })
    .derive({ index: () => op.row_number() })
    .objects()
    .slice(0, 5);

  if (data.length === 0) {
    return <></>;
  }

  const dcolumns: ColumnDef<{}>[] = [
    {
      accessorKey: "index",
      header: "No.",

      cell: ({ row }) => <div className="w-5">{row.getValue("index")}</div>,
    },
    {
      accessorKey: "disposal_site_name",
      header: ({ column }) => {
        return (
          <div
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className=" inline-flex p-0 w-full gap-1 cursor-pointer"
          >
            業者名
            <ChevronsUpDown className="h-4 w-4" />
          </div>
        );
      },
      cell: ({ row }) => (
        <div className="relative group w-32">
          <div className="text-left overflow-hidden whitespace-nowrap  overflow-ellipsis">
            {row.getValue("disposal_site_name")}
          </div>
          {(row.getValue("disposal_site_name") as string).length > 6 && (
            <div className="absolute whitespace-nowrap text-white bg-gray-700 opacity-0 group-hover:opacity-100 group-hover:z-0 -z-10 -top-8 -left-5">
              {row.getValue("disposal_site_name")}
            </div>
          )}
        </div>
      ),
    },
    {
      accessorKey: "sum",
      cell: ({ row }) => (
        <p>
          {floor(row.getValue("sum")).toLocaleString("jp-ja", {
            maximumFractionDigits: 1,
          })}
        </p>
      ),
      header: ({ column }) => {
        return (
          <div
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="whitespace-nowrap inline-flex p-0 text-left w-12"
          >
            廃棄物量({unit})
            <ChevronsUpDown className="h-4 w-4" />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-[309px] h-[331px] bg-white shadow-lg rounded-xl border border-zinc-100 flex flex-col p-3.5 ">
      <div className="flex flex-col items-center h-14 text-gray-800">
        <div className="font-bold text-base text-start w-full">{name}</div>
        <div className=" w-full text-end">
          <p>
            合計
            <span className="text-2xl pl-6 pr-1">
              {floor(
                filteredTable
                  .filter(escape((d: any) => d[filterKey] === name))
                  .rollup({ sum: op.sum(`waste_amount_${unit}`) })
                  .get("sum") || 0
              ).toLocaleString("jp-ja", { maximumFractionDigits: 1 })}
            </span>
            {unit}
          </p>
        </div>
      </div>
      <div className="h-12 w-full">
        <ResponsiveContainer>
          <BarChart
            layout="vertical"
            data={barData}
            margin={{
              bottom: 5,
            }}
          >
            <XAxis type="number" hide={true} />
            <YAxis type="category" hide={true} />
            {Object.keys(barData[0]).map((key, i) => (
              <Bar
                key={key}
                dataKey={key}
                barSize={20}
                stackId="a"
                fill={colors[i % colors.length]}
                activeBar={false}
              />
            ))}
            <Tooltip content={CustomTooltip} cursor={false} shared={false} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div>
        <DataTable columns={dcolumns} data={data} />
      </div>
    </div>
  );
}
