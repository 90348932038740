import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ja";
import useStore from "../lib/store";

const MonthPicker = () => {
  const [startMonth, setStartMonth, endMonth, setEndMonth] = useStore(
    (state) => [
      state.startMonth,
      state.setStartMonth,
      state.endMonth,
      state.setEndMonth,
    ],
  );
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        height: "32px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
        <DatePicker
          sx={{
            width: "125px",
            "& .MuiInputBase-root": {
              // テキストフィールドのルート要素をターゲットに
              borderRadius: "8px", // 角丸を8pxに設定
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.08)",
            },
            "& .MuiInputBase-input": {
              padding: 0,
              paddingLeft: "10px",
              height: "32px",
              fontSize: "12px",
            },
          }}
          slotProps={{
            textField: { size: "small" },
          }}
          views={["month", "year"]}
          defaultValue={startMonth}
          format="YYYY年MM月"
          // minDate={startMonth.subtract(1, "year")}
          maxDate={endMonth}
          onChange={(value) => {
            setStartMonth(value!!);
          }}
        />
        <div
          style={{
            width: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "32px",
            color: "#727272",
          }}
        >
          <div>〜</div>
        </div>
        <DatePicker
          sx={{
            width: "125px",
            "& .MuiInputBase-root": {
              // テキストフィールドのルート要素をターゲットに
              borderRadius: "8px", // 角丸を8pxに設定
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.08)",
            },
            "& .MuiInputBase-input": {
              padding: 0,
              paddingLeft: "10px",
              height: "32px",
              fontSize: "12px",
            },
          }}
          slotProps={{
            textField: { size: "small" },
          }}
          views={["month", "year"]}
          defaultValue={endMonth}
          format="YYYY年MM月"
          minDate={startMonth}
          // maxDate={startMonth.add(1, "year")}
          onChange={(value) => {
            setEndMonth(value!!);
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default MonthPicker;
