import { escape, op } from "arquero";
import { useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import useStore from "../../lib/store";
import { nonRecycleTypes } from "../../lib/utils";
import { Checkbox } from "../ui/checkbox";

export default function CompanySiteDashboard() {
  const [filteredTable, unit] = useStore((state) => [
    state.filteredTable,
    state.unit,
  ]);
  const wasteTableData = filteredTable
    .groupby("company_site_name")
    .rollup({
      value: op.sum(`waste_amount_${unit}`),
    })
    .objects();

  const co2TableData = filteredTable
    .groupby("company_site_name")
    .rollup({
      value: op.sum("co2_amount"),
    })
    .objects();

  const recycleTableData = filteredTable
    .derive({
      rec: escape((d: any) =>
        nonRecycleTypes.includes(d["recycle_type"]) ? 0 : d["waste_amount_kg"]
      ),
    })
    .select("rec", "waste_amount_kg", "company_site_name")
    .groupby("company_site_name")
    .rollup({
      rec: op.sum("rec"),
      sum: op.sum("waste_amount_kg"),
    })
    .derive({
      value: (d: any) => (d["rec"] / d["sum"]) * 100,
    })
    .objects();

  const [showWaste, setShowWaste] = useState(true);
  const [showRecycle, setShowRecycle] = useState(true);
  const [showCO2, setShowCO2] = useState(true);

  const numOfActive = [showWaste, showRecycle, showCO2].filter((x) => x).length;

  const CustomizedLabel = ({
    x,
    dx,
    y,
    value,
  }: {
    x: number;
    dx: number;
    y: number;
    value: number;
  }) => {
    let offset: number = 0;
    switch (numOfActive) {
      case 1:
        offset = 750;
        break;
      case 2:
        offset = 270;
        break;
      case 3:
        offset = 110;
        break;
    }
    return (
      <text
        x={x}
        dx={x + offset}
        y={y}
        dy={13}
        fill="gray"
        fontSize={10}
        textAnchor="middle"
      >
        {value.toLocaleString("jp-ja", { maximumFractionDigits: 1 })}
      </text>
    );
  };

  const siteNames = filteredTable
    .groupby("company_site_name")
    .rollup({
      value: op.sum(`waste_amount_${unit}`),
    })
    .array("company_site_name");

  const BarViz = ({
    data,
    fill,
    name,
  }: {
    data: any[];
    fill: string;
    name: string;
  }) => {
    return (
      <ResponsiveContainer width="100%" height={siteNames.length * 35}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            right: 50,
          }}
        >
          <XAxis
            type="number"
            orientation="top"
            tickFormatter={(tick) =>
              tick.toLocaleString("jp-ja", { maximumFractionDigits: 1 })
            }
          />
          <YAxis
            dataKey="company_site_name"
            type="category"
            tickLine={false}
            axisLine={false}
            width={80}
            style={{
              fontSize: "0.6rem",
            }}
          />
          <Bar
            dataKey="value"
            fill={fill}
            barSize={20}
            background={{ fill: "#eee" }}
            name={name}
            label={CustomizedLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="hidden-scrollbar flex flex-col h-full p-6 overflow-scroll bg-[url('img/company_background.png')] subpage-bg-half-size bg-[0px_-30px] bg-no-repeat">
      <div className="bg-white rounded-xl py-6">
        <div className="flex border-b-2 pb-3 ps-6 text-sm">
          <div className="flex">
            <div>表示項目</div>
            <div className="flex items-center ps-6">
              <Checkbox
                id="waste"
                checked={showWaste}
                onCheckedChange={(checked) => setShowWaste(checked as boolean)}
              />
              <label htmlFor="waste" className="mx-2">
                廃棄物排出量
              </label>
            </div>
            <div className="flex items-center ps-3">
              <Checkbox
                id="recycle"
                checked={showRecycle}
                onCheckedChange={(checked) =>
                  setShowRecycle(checked as boolean)
                }
              />
              <label htmlFor="recycle" className="mx-2 ">
                総リサイクル率
              </label>
            </div>
            <div className="flex items-center ps-3">
              <Checkbox
                id="co2"
                checked={showCO2}
                onCheckedChange={(checked) => setShowCO2(checked as boolean)}
              />
              <label htmlFor="co2" className="mx-2">
                CO2排出量
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center pt-6 px-8 gap-6">
          {showWaste && (
            <div className="w-full">
              <div className="text-sm font-semibold">
                廃棄物排出量 （単位 : {unit}）
              </div>
              <div>
                <BarViz
                  data={wasteTableData}
                  fill="green"
                  name="廃棄物排出量"
                />
              </div>
            </div>
          )}
          {showRecycle && (
            <div className="w-full">
              <div className="text-sm font-semibold">
                総リサイクル率 （単位 : %）
              </div>
              <div>
                <BarViz
                  data={recycleTableData}
                  fill="green"
                  name=">総リサイクル率"
                />
              </div>
            </div>
          )}
          {showCO2 && (
            <div className="w-full">
              <div className="text-sm font-semibold">
                CO2排出量 （単位 : kg）
              </div>
              <div>
                <BarViz
                  data={co2TableData}
                  fill="lightblue"
                  name="CO2排出量 "
                />
              </div>
            </div>
          )}
        </div>
        <img
          src={require("img/company_panel_park.png")}
          alt=""
          className="pt-16"
        />
      </div>
    </div>
  );
}
