import useStore from "../lib/store";
import { dashboardNames } from "../lib/appUtils";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectContent,
} from "./ui/select";
import MonthPicker from "./MonthPicker";
import { recycleTypeOrder } from "../lib/utils";
import { Button } from "./ui/button";
import { Download } from "lucide-react";

export default function NavBar({
  companySites,
  recycleTypes,
}: {
  companySites: string[];
  recycleTypes: string[];
}) {
  const [
    dashboardIndex,
    setUnit,
    companySite,
    recycleType,
    setCompanySite,
    setRecycleType,
  ] = useStore((state) => [
    state.dashboardIndex,
    state.setUnit,
    state.companySite,
    state.recycleType,
    state.setCompanySite,
    state.setRecycleType,
  ]);

  recycleTypes.sort(
    (a: string, b: string) =>
      recycleTypeOrder.indexOf(a) - recycleTypeOrder.indexOf(b)
  );

  return (
    <div
      className={`${
        dashboardIndex === 0 ? "" : "bg-white"
      } flex gap-2 w-full h-[100px] py-[24px] px-[24px] justify-between items-center text-xs abc`}
    >
      <h2 className="text-current text-lg font-bold">
        {dashboardNames[dashboardIndex]}
      </h2>
      <div className="py-[8px] flex gap-2.5 justify-end bg-white/90">
        <div className="flex items-center gap-2.5">
          <div className="text-gray-500 text-xs font-bold">表記単位(kg/m3)</div>
          <div>
            <Select onValueChange={setUnit}>
              <SelectTrigger className="w-16 h-8 p-3 text-xs">
                <SelectValue placeholder="kg" />
              </SelectTrigger>
              <SelectContent className="w-16 min-w-0">
                <SelectItem className="text-xs" value="kg">
                  kg
                </SelectItem>
                <SelectItem className="text-xs" value="m3">
                  m3
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex gap-1.5 items-center">
          <div className="text-gray-500 font-bold">事業場別</div>
          <Select value={companySite} onValueChange={setCompanySite}>
            <SelectTrigger className="w-20 h-8 text-xs">
              <span className="text-ellipsis overflow-hidden whitespace-nowrap">
                <SelectValue placeholder="全て" />
              </span>
            </SelectTrigger>
            <SelectContent className="min-w-0 ">
              <SelectItem className="" key="全て" value="全て">
                全て
              </SelectItem>
              {companySites.map((site) => (
                <SelectItem className="" key={site} value={site}>
                  {site}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex gap-2.5 items-center">
          <div className="text-gray-500  font-bold">処理方法</div>
          <Select value={recycleType} onValueChange={setRecycleType}>
            <SelectTrigger className="w-20 h-8 text-xs">
              <span className="text-ellipsis overflow-hidden whitespace-nowrap">
                <SelectValue className="text-ellipsis" placeholder="全て" />
              </span>
            </SelectTrigger>
            <SelectContent className="min-w-0 ">
              <SelectItem className="" key="全て" value="全て">
                全て
              </SelectItem>
              {recycleTypes.map((recycleType) => (
                <SelectItem className="" key={recycleType} value={recycleType}>
                  {recycleType}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex gap-2.5 items-center">
          <div className="text-gray-500 font-bold">表示期間</div>
          <MonthPicker />
        </div>
        <Button
          variant="outline"
          size="icon"
          className="w-8 h-8"
          onClick={window.print}
        >
          <Download className="text-current w-5 h-5" />
        </Button>
      </div>
    </div>
  );
}
