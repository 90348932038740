import { ColumnDef } from "@tanstack/react-table";
import { op } from "arquero";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import useStore from "../../lib/store";
import { floor, wasteOrder, wastePalette } from "../../lib/utils";
import { CustomTooltip } from "../CustomTooltip";
import { DataTable } from "../DataTable";
import DetailCard from "../DetailCard";

export default function EmissionDashboard() {
  const [filteredTable, unit] = useStore((state) => [
    state.filteredTable,
    state.unit,
  ]);

  const waste_amount_unit = `waste_amount_${unit}`;

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "report_type_name",
      header: "",
      enableResizing: true,
      cell: ({ row }: { row: any }) => {
        const value = row.getValue("report_type_name");
        return (
          <p className="flex gap-2 items-center">
            <span
              className="h-3 w-3 inline-block"
              style={{
                backgroundColor: (wastePalette[value] || [""])[0],
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            {value}
          </p>
        );
      },
    },
    ...Array.from({ length: 12 }, (_, i) => ({
      accessorKey: `${i}`,
      header: `${i + 1}月`,
      cell: ({ row }: { row: any }) => {
        const value = row.getValue(`${i}`);
        if (value === undefined) {
          return 0;
        }
        return floor(value).toLocaleString("jp-ja", {
          maximumFractionDigits: 1,
        });
      },
    })),
  ];

  const _tableData = filteredTable
    .derive({ month: (d) => op.month(d!!.date) })
    .select("report_type_name", "month", waste_amount_unit)
    .groupby("report_type_name");

  const tableData =
    unit === "kg"
      ? _tableData
          .pivot("month", {
            value: (d: any) => op.sum(d["waste_amount_kg"]),
          })
          .objects()
      : _tableData
          .pivot("month", {
            value: (d: any) => op.sum(d["waste_amount_m3"]),
          })
          .objects();

  tableData.sort(
    (a: any, b: any) =>
      wasteOrder.indexOf(a["report_type_name"]) -
      wasteOrder.indexOf(b["report_type_name"])
  );

  const _barData = filteredTable
    .derive({ month: (d) => op.month(d!!.date) })
    .select("report_type_name", "month", waste_amount_unit)
    .groupby("month");

  const barData =
    unit === "kg"
      ? _barData
          .pivot("report_type_name", {
            value: (d: any) => op.sum(d["waste_amount_kg"]),
          })
          .objects()
      : _barData
          .pivot("report_type_name", {
            value: (d: any) => op.sum(d["waste_amount_m3"]),
          })
          .objects();

  return (
    <div className="hidden-scrollbar flex flex-col gap-8 h-full pt-8 px-6 overflow-scroll bg-[url('img/emissions_background_with-illust.png')] subpage-bg-half-size bg-[0px_-40px] bg-no-repeat">
      <div className="">
        <div className="text-xl font-bold my-3 border-l-4 px-2 border-[#e60013] mt-6 mb-6">
          品目別 処分先
        </div>
        <div className="flex justify-start flex-wrap gap-3">
          {filteredTable
            .groupby("report_type_name")
            .orderby("report_type_name")
            .count()
            .array("report_type_name")
            .map((name: string) => (
              <DetailCard
                name={name}
                key={name}
                filterKey="report_type_name"
                colors={wastePalette[name] || ["#8884d8", "#82ca9d", "#abaa88"]}
              />
            ))}
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-md border border-zinc-100 flex flex-col p-5 gap-3.5 mb-12">
        <h3 className="font-bold">廃棄物排出量 月次 品目別グラフ</h3>
        <div className="h-[175px] w-full flex">
          <img
            src={require("img/emissions_factory1.png")}
            alt=""
            className="h-36 w-40"
          />
          <ResponsiveContainer>
            <BarChart data={barData}>
              <XAxis
                dataKey="month"
                tickFormatter={(month) => `${month + 1}月`}
                axisLine={false}
                tickLine={false}
              />
              {Object.keys(barData[0])
                .sort(
                  (a: any, b: any) =>
                    wasteOrder.indexOf(b) - wasteOrder.indexOf(a)
                )
                .map(
                  (key, i) =>
                    key !== "month" && (
                      <Bar
                        background={i === 0 ? { fill: "#EBEBEB" } : undefined}
                        key={key}
                        dataKey={key}
                        stackId="a"
                        fill={(wastePalette[key] || [""])[0]}
                      />
                    )
                )}
              <Tooltip cursor={false} content={CustomTooltip} shared={false} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <DataTable data={tableData} columns={columns} />
      </div>
    </div>
  );
}
