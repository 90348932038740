import { GraphQLQuery } from "@aws-amplify/api";
import { SignOut } from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator";
import { op } from "arquero";
import ColumnTable from "arquero/dist/types/table/column-table";
import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  Building2,
  Cloudy,
  Layers2,
  LogOut,
  LucideIcon,
  Recycle,
  Trash2,
} from "lucide-react";
import { useEffect } from "react";
import { RecordsByCompany_nameAndDateQuery } from "./API";
import Logo from "./components/Logo";
import NavBar from "./components/NavBar";
import Co2Dashboard from "./components/dashboards/Co2Dashboard";
import CompanySiteDashboard from "./components/dashboards/CompanySiteDashboard";
import EmissionDashboard from "./components/dashboards/EmissionDashboard";
import RecyleDashboard from "./components/dashboards/RecyleDashboard";
import SummaryDashboard from "./components/dashboards/SummaryDashboard";
import { Button } from "./components/ui/button";
import { recordsByCompany_nameAndDate } from "./graphql/queries";
import { dashboardNames } from "./lib/appUtils";
import useStore from "./lib/store";
import { cn } from "./lib/utils";

const NavBarItem = ({
  name,
  SideIcon,
  selected,
  onClick,
}: {
  name: string;
  SideIcon: LucideIcon;
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      variant="ghost"
      className={cn(
        "h-10 text-slate-500 flex gap-1 border-r-2 rounded-none justify-start",
        selected ? "border-red-500" : "border-transparent"
      )}
      onClick={onClick}
    >
      <SideIcon className={cn("w-[16px]", selected ? "text-current" : "")} />
      <p
        className={cn(
          "font-medium text-[12px] py-[6px] pl-[8px]",
          selected ? "text-current font-semibold" : ""
        )}
      >
        {name}
      </p>
    </Button>
  );
};

interface HomeProps {
  signOut?: SignOut;
}

export default function Home({ signOut }: HomeProps) {
  const [
    dashboardIndex,
    setDashboardIndex,
    companyName,
    setCompanyName,
    table,
    setTable,
    userName,
    setUserName,
  ] = useStore((state) => [
    state.dashboardIndex,
    state.setDashboardIndex,
    state.companyName,
    state.setCompanyName,
    state.table,
    state.setTable,
    state.userName,
    state.setUserName,
  ]);

  function getCompanySites(table: ColumnTable): string[] {
    return table
      .groupby("company_site_name")
      .rollup({ count: op.count() })
      .array("company_site_name");
  }

  function getRecycleTypes(table: ColumnTable) {
    return table
      .groupby("recycle_type")
      .rollup({ count: op.count() })
      .array("recycle_type");
  }

  useEffect(() => {
    const fetch = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setUserName(user.username);
      setCompanyName(
        user.signInUserSession.accessToken.payload["cognito:groups"][0]
      );
    };
    fetch();
  }, [setCompanyName, setUserName]);

  useEffect(() => {
    const fetch = async () => {
      let records: any[] = [];

      let result = await API.graphql<
        GraphQLQuery<RecordsByCompany_nameAndDateQuery>
      >(
        graphqlOperation(recordsByCompany_nameAndDate, {
          limit: 10000,
          company_name: companyName,
        })
      );
      records = records.concat(
        result.data?.recordsByCompany_nameAndDate?.items
      );
      while (result.data?.recordsByCompany_nameAndDate?.nextToken) {
        result = await API.graphql(
          graphqlOperation(recordsByCompany_nameAndDate, {
            limit: 10000,
            company_name: companyName,
            nextToken: result.data.recordsByCompany_nameAndDate.nextToken,
          })
        );
        records = records.concat(
          result.data?.recordsByCompany_nameAndDate?.items
        );
      }
      if (records.length > 0) {
        setTable(records);
      }
    };
    if (companyName.length > 0) {
      fetch();
    }
  }, [companyName, setTable]);

  return table.count().get("count", 0) <= 0 ? (
    <></>
  ) : (
    <div className="flex min-h-[100vh] font-sans">
      <div className="z-[1] flex flex-col w-[160px] justify-between border-r shadow-[rgba(0,0,0,0.09)_8px_4px_8px_-4px] border-zinc-100">
        <div className="flex flex-col w-full justify-between">
          <Logo
            className="w-full my-8 hover:cursor-pointer"
            onClick={() => setDashboardIndex(0)}
          />
          <NavBarItem
            name={dashboardNames[0]}
            SideIcon={Layers2}
            selected={dashboardIndex === 0}
            onClick={() => setDashboardIndex(0)}
          />
          <NavBarItem
            name={dashboardNames[1]}
            SideIcon={Trash2}
            selected={dashboardIndex === 1}
            onClick={() => setDashboardIndex(1)}
          />
          <NavBarItem
            name={dashboardNames[2]}
            SideIcon={Recycle}
            selected={dashboardIndex === 2}
            onClick={() => setDashboardIndex(2)}
          />
          <NavBarItem
            name={dashboardNames[3]}
            SideIcon={Cloudy}
            selected={dashboardIndex === 3}
            onClick={() => setDashboardIndex(3)}
          />
          <NavBarItem
            name={dashboardNames[4]}
            SideIcon={Building2}
            selected={dashboardIndex === 4}
            onClick={() => setDashboardIndex(4)}
          />
        </div>
        <div className="sticky bottom-0 left-0 w-full border-t h-14 border-zinc-100 py-4">
          <p className="flex items-center m-auto px-2 text-[11px] text-slate-500 justify-between">
            {companyName}
            <LogOut onClick={signOut} />
          </p>
        </div>
      </div>
      <div
        className={`${
          dashboardIndex === 0
            ? "bg-[url('./img/summary_bg_city.png')] bg-no-repeat bg-right-top"
            : ""
        } flex flex-col w-[1040px] h-full items-center bg-half-size`}
      >
        <NavBar
          companySites={getCompanySites(table)}
          recycleTypes={getRecycleTypes(table)}
        />
        <div className="min-h-screen w-full">
          {dashboardIndex === 0 && <SummaryDashboard />}
          {dashboardIndex === 1 && <EmissionDashboard />}
          {dashboardIndex === 2 && <RecyleDashboard />}
          {dashboardIndex === 3 && <Co2Dashboard />}
          {dashboardIndex === 4 && <CompanySiteDashboard />}
        </div>
      </div>
    </div>
  );
}
