const dashboardNames = [
  "サマリーレポート",
  "排出量",
  "リサイクル率",
  "CO2 - 詳細",
  "事業場",
];

const column_mapping = {
  交付年月日: "date",
  排出事業者CD: "company_cd",
  排出事業者名: "company_name",
  排出事業場CD: "company_site_cd",
  排出事業場: "company_site_name",
  報告書分類名: "report_type_name",
  廃棄物の確定数量: "waste_amount",
  廃棄物の確定数量単位名: "waste_amount_unit",
  "換算数量(kg)": "waste_amount_kg",
  "換算単位(kg)": "waste_amount_kg_unit",
  "換算数量(㎥)": "waste_amount_m3",
  "換算単位(㎥)": "waste_amount_m3_unit",
  運搬情報収集運搬業者名: "transport_company_name",
  処分情報処分業者名: "disposal_company_name",
  "最終処分の場所(実績)最終処分事業場名": "disposal_site_name",
  リサイクル区分: "recycle_type",
  CO2排出量: "co2_amount",
  リサイクル対象: "recycle_target",
};

const columns = [
  {
    accessorKey: "date",
    header: "引渡日",
    maxSize: 200,
    size: 150,
  },
  {
    accessorKey: "company_cd",
    header: "排出事業者CD",
  },
  {
    accessorKey: "company_name",
    header: "排出事業者名",
  },
  {
    accessorKey: "company_site_cd",
    header: "排出事業場CD",
  },
  {
    accessorKey: "company_site_name",
    header: "排出事業場",
  },
  {
    accessorKey: "report_type_name",
    header: "報告書分類名",
  },
  {
    accessorKey: "waste_amount",
    header: "廃棄物の確定数量",
  },
  {
    accessorKey: "waste_amount_unit",
    header: "廃棄物の確定数量単位名",
  },
  {
    accessorKey: "waste_amount_kg",
    header: "換算数量(kg)",
    maxSize: 400,
    size: 100,
  },
  {
    accessorKey: "waste_amount_kg_unit",
    header: "換算単位(kg)",
  },
  {
    accessorKey: "waste_amount_m3",

    header: "換算数量(㎥)",
  },
  {
    accessorKey: "waste_amount_m3_unit",
    header: "換算単位(㎥)",
  },
  {
    accessorKey: "transport_company_name",
    header: "運搬情報収集運搬業者名",
  },
  {
    accessorKey: "disposal_company_name",
    header: "処分情報処分業者名",
  },
  {
    accessorKey: "disposal_site_name",
    header: "最終処分の場所(実績)最終処分事業場名",
  },
  {
    accessorKey: "recycle_type",
    header: "リサイクル区分",
  },
  {
    accessorKey: "co2_amount",
    header: "CO2排出量(kg)",
  },
  {
    accessorKey: "recycle_target",
    header: "リサイクル対象",
    Cell: (value) => (value ? "対象" : "非対象"),
  },
];

const recycle_types = [
  "サーマル",
  "マテリアル",
  "埋立",
  "オーガニック",
  "焼却後埋立",
];

function getPieData(records, groupBy) {
  const data = {};
  for (let i = 0; i < records.length; i++) {
    const record = records[i];
    if (!data[record[groupBy]]) {
      data[record[groupBy]] = 1;
    } else {
      data[record[groupBy]]++;
    }
  }
  const ret = [];
  Object.keys(data).forEach((key) => {
    ret.push({ name: key, value: data[key] });
  });

  return ret;
}

function calculateRecycleRatios(data, groupKey = null) {
  const groups = {};

  data.forEach((item) => {
    const groupValue = groupKey ? item[groupKey] : "overall";

    if (!groups[groupValue]) {
      groups[groupValue] = { numerator: 0, denominator: 0 };
    }

    if (item.recycle_type !== "埋立") {
      groups[groupValue].numerator += item.waste_amount_kg;
    }

    groups[groupValue].denominator += item.waste_amount_kg;
  });

  return Object.entries(groups).map(
    ([groupValue, { numerator, denominator }]) => ({
      [groupKey || "overall"]: groupValue,
      recycle_ratio: Math.round((numerator / denominator) * 100),
    })
  );
}

export {
  calculateRecycleRatios,
  column_mapping,
  columns,
  dashboardNames,
  getPieData,
  recycle_types,
};
