import { ColumnDef } from "@tanstack/react-table";
import { escape, op } from "arquero";
import { useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import useStore from "../../lib/store";
import {
  nonRecycleTypes as _nonRecycleTypes,
  floor,
  recyclePalette,
  recycleTypeOrder,
  wasteOrder,
} from "../../lib/utils";
import DetailCard, { DataTable } from "../DetailCard";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export default function RecyleDashboard() {
  const [recycleType] = useStore((state) => [state.recycleType]);
  const [reportType, setReportType] = useState<string>("全て");
  const [table, _filteredTable, unit] = useStore((state) => [
    state.table,
    state.filteredTable,
    state.unit,
  ]);

  const filteredTable = _filteredTable.filter(
    escape((d: any) =>
      reportType === "全て" ? true : d["report_type_name"] === reportType
    )
  );

  const allReportTypes = _filteredTable
    .groupby("report_type_name")
    .rollup({
      count: op.count(),
    })
    .array("report_type_name");

  allReportTypes.sort(
    (a: string, b: string) => wasteOrder.indexOf(a) - wasteOrder.indexOf(b)
  );

  const allRecycleTypes = filteredTable
    .groupby("recycle_type")
    .rollup({
      count: op.count(),
    })
    .array("recycle_type");

  const nonRecycleTypes =
    recycleType === "全て"
      ? _nonRecycleTypes
      : _nonRecycleTypes.filter((type) => type === recycleType);

  const recycleTypes = allRecycleTypes.filter(
    (type: string) =>
      !nonRecycleTypes.includes(type) &&
      (recycleType === "全て" ? true : type === recycleType)
  );

  recycleTypes.sort(
    (a: string, b: string) =>
      recycleTypeOrder.indexOf(a) - recycleTypeOrder.indexOf(b)
  );

  const tableData = filteredTable
    .derive({ month: (d: any) => op.month(d.date) })
    .select("recycle_type", "month", `waste_amount_${unit}`, "co2_amount")
    .groupby("recycle_type")
    .pivot("month", { value: op.sum(`waste_amount_${unit}`) })
    .objects();

  tableData.sort(
    (a: any, b: any) =>
      recycleTypeOrder.indexOf(a["recycle_type"]) -
      recycleTypeOrder.indexOf(b["recycle_type"])
  );

  const monthlyStats = Array.from({ length: 12 }, () => ({
    totalWeight: 0,
    recyclableWeight: 0,
  }));

  tableData.forEach((row: any) => {
    for (let i = 0; i < 12; i++) {
      if (row[i] !== undefined) {
        monthlyStats[i].totalWeight += row[i];
        if (!nonRecycleTypes.includes(row.recycle_type)) {
          monthlyStats[i].recyclableWeight += row[i];
        }
      }
    }
  });

  const recycleRatios: Record<string, string | number> = Object.fromEntries(
    monthlyStats
      .map((month) => (month.recyclableWeight / month.totalWeight) * 100 || 0)
      .map((ratio, index) => [index, ratio])
  );
  recycleRatios["recycle_type"] = "リサイクル率";
  tableData.push(recycleRatios);

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "recycle_type",
      header: "",
      enableResizing: true,
      cell: ({ row }: { row: any }) => {
        const value = row.getValue("recycle_type");
        return (
          <p className="flex gap-2 items-center">
            <span
              className="h-3 w-3 inline-block"
              style={{
                backgroundColor: (recyclePalette[value] || [""])[0],
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            {value}
          </p>
        );
      },
    },
    ...Array.from({ length: 12 }, (_, i) => ({
      accessorKey: `${i}`,
      header: `${i + 1}月`,
      cell: ({ row }: { row: any }) => {
        const value = row.getValue(`${i}`);
        if (row.getValue("recycle_type") === "リサイクル率") {
          return `${value.toLocaleString("jp-ja", {
            maximumFractionDigits: 1,
          })}%`;
        }
        if (value === undefined) {
          return 0;
        }
        return floor(value).toLocaleString("jp-ja", {
          maximumFractionDigits: 1,
        });
      },
    })),
  ];

  const pieData: any[] = table
    .groupby("recycle_type")
    .rollup({
      sum: op.sum(`waste_amount_${unit}`),
    })
    .rename({ sum: "value", recycle_type: "name" })
    .objects();

  let totalWaste = 0;
  let recycleWaste = 0;

  for (let i = 0; i < pieData.length; i++) {
    totalWaste += pieData[i].value || 0;
    recycleWaste += !nonRecycleTypes.includes(pieData[i].name)
      ? pieData[i].value || 0
      : 0;
  }

  return (
    <div className="hidden-scrollbar flex flex-col h-full px-6 gap-5 overflow-scroll py-6 bg-[url('img/recycle_background_with-illust.png')] subpage-bg-half-size bg-auto bg-scroll bg-no-repeat bg-right-top">
      <div className="bg-white w-full h-16 border rounded-md flex p-4 items-center gap-3">
        <div>品目別</div>
        <div>
          <Select onValueChange={setReportType}>
            <SelectTrigger className="w-32 h-8 p-3 whitespace-nowrap overflow-ellipsis overflow-hidden ">
              <SelectValue className=" " placeholder={reportType} />
            </SelectTrigger>
            <SelectContent className=" min-w-0">
              <SelectItem className="text-xs" value="全て">
                全て
              </SelectItem>
              {allReportTypes.map((type: string) => (
                <SelectItem className="text-xs" key={type} value={type}>
                  {type}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex flex-col">
        <div>
          <div className="flex items-center gap-5">
            <div className="text-xl font-bold my-3 border-l-4 px-2 border-[#e60013] mt-6 mb-6">
              リサイクル対象 処分先
            </div>
            <div className="inline-flex">
              合計
              <p>
                {(
                  filteredTable
                    .filter(
                      escape((d: any) =>
                        recycleTypes.includes(d["recycle_type"])
                      )
                    )
                    .rollup({ sum: op.sum(`waste_amount_${unit}`) })
                    .get("sum") || 0
                ).toLocaleString("jp-ja", { maximumFractionDigits: 1 })}
              </p>
              {unit}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3">
            {recycleTypes.map((type: string, index: number) => {
              let alignmentClass = "";
              if (index % 3 === 0) {
                alignmentClass = "items-end";
              } else if (index % 3 === 1) {
                alignmentClass = "items-start";
              } else {
                alignmentClass = "items-end";
              }

              return (
                <div
                  className={`flex h-[480px] w-full ${alignmentClass} justify-center`}
                  key={type}
                >
                  <DetailCard
                    name={type}
                    filterKey="recycle_type"
                    colors={
                      recyclePalette[type] || ["#8884d8", "#82ca9d", "#abaa88"]
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div className="flex items-center gap-5">
            <div className="text-xl font-bold my-3 border-l-4 px-2 border-[#e60013] mt-16 mb-6">
              リサイクル対象外 処分先
            </div>
            <div className="inline-flex mt-16 mb-6">
              合計
              <p>
                {(
                  filteredTable
                    .filter(
                      escape((d: any) =>
                        nonRecycleTypes.includes(d["recycle_type"])
                      )
                    )
                    .rollup({ sum: op.sum(`waste_amount_${unit}`) })
                    .get("sum") || 0
                ).toLocaleString("jp-ja", { maximumFractionDigits: 1 })}
              </p>
              {unit}
            </div>
          </div>
          <div className="flex justify-start gap-6">
            {nonRecycleTypes.map((type: string) => (
              <DetailCard
                name={type}
                key={type}
                filterKey="recycle_type"
                colors={
                  recyclePalette[type] || ["#8884d8", "#82ca9d", "#abaa88"]
                }
              />
            ))}
            <img
              src={require("img/recycle_bg_factory3.png")}
              alt=""
              className="w-1/3 h-1/3"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-white border rounded-md shadow-sm border-zinc-100 p-4 gap-3 pb-12">
        <div>リサイクル方法別　排出量（月次）</div>
        <div className="flex  gap-3">
          <div className="w-32">
            <ResponsiveContainer>
              <PieChart className="pt-2">
                <Pie
                  data={pieData}
                  dataKey="value"
                  nameKey="name"
                  cx={50}
                  cy={100}
                  innerRadius={35}
                  outerRadius={50}
                  fill="#82ca9d"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={(recyclePalette[entry["name"]] || [""])[0]}
                    />
                  ))}
                </Pie>
                <g>
                  <text
                    x={55}
                    y={105}
                    textAnchor="middle"
                    fill="fff"
                    fontSize="0.5em"
                  >
                    リサイクル率
                  </text>
                  <text
                    x={55}
                    y={115}
                    textAnchor="middle"
                    fill="fff"
                    fontSize="0.5em"
                  >
                    {((recycleWaste / totalWaste) * 100 || 0).toLocaleString(
                      "jp-ja",
                      { maximumFractionDigits: 1 }
                    )}
                    %
                  </text>
                </g>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div>
            <DataTable data={tableData} columns={columns} />
          </div>
        </div>
      </div>
    </div>
  );
}
